@media only screen and (max-width: 1000px){
    .card.link {
        margin-bottom: 20px;
    }
}

    @media only screen and (max-width: 1200px){
        i {
            display: none !important;
        }
}

.box{
    background-color: red;
}

.card.link{
    transition-duration: 0.3s;
    height: 300px;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.44);
}

.salutation {
    text-align: center;
}

.card.link:hover{
    transform: scale(1.05);
    cursor: pointer;
    transition-duration: 0.3s;
    box-shadow: 0px 4px 8px rgba(0,0,0,0.22);

}

.card.link .title {
    padding: 14px 24px;
    color: #FFFFFF;
    border-radius: 4px;
    border-width: 1px;
    border-color: #fff;
    border-style: solid;
    position: absolute;
    margin: 0 60px 52px 24px;
    bottom: 40px;
}

.card.link .card-footer {
    color: #fff;
    padding: 24px;
    min-height: 64px;
    margin: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    border: none;
}

.card-footer .row {
    display: block !important;

}

.card .material-icons {
    color: #fff;
    display: block;
}

.card-footer .arrow {
    text-align: right;
}

.card-footer h3 {
    display: inline-block;
    margin: 0;
}
