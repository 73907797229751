@font-face {
    font-family: ProximaNova;
    src: url('https://s3.amazonaws.com/100-cdn-sti/Janssen/assets/ProximaNova.otf');
}

html, body {
    position: relative;
    font-family: "ProximaNova", sans-serif;
    font-size: 10px;
    min-height: 100vh;
    margin: 0;
    margin-bottom: -60px;
    padding: 0;
}

#root {
    min-height: 100%;
}

hr {
    margin-top: 80px;
}

.welcome p {
    margin-bottom: 50px;
}