.footer {
    position: absolute;
    width: 100%;
    height: auto;
    font-size: 1.4rem;
    background-color: #181818;
    color: #dadada;
    text-align: center;
    padding: 10px;
    
    bottom: 0;
}

.footer p {
    margin-bottom: 0px;
    margin-top: 0px;
    width: 70%;
    color: #dadada;
    font-size: 1.5rem;
    text-align: center;
}

.footer strong {
    white-space: nowrap;
}

.footer strong .anchor {
    color: #dadada;
}