@media only screen and (max-width: 576px){
    .divider {
      display: block;
      height: 1px !important;
      width: 130px !important;
      border-width: 0 0 1px 0 !important;
    }
    .tagline-wrapper {
        display: block !important;
    }
}

.navbar-brand {
    height: auto !important;
    vertical-align: middle;
}

.navbar .logo {
   height: 60px;
}

.navbar .tagline {
    height: 40px;
 }

.navbar-brand .tagline-wrapper, .navbar-brand .logo{
    display: inline-block;
}


.menu {
    margin-right: 15px;
}

.divider {
    border-width: 0 1px 0 0;
    width: 1px;
    padding: 0px;
    margin: 0 10px 0 0;
    border-style: solid;
    border-color: #7d7d7d;   
}

