.loader-wrapper {
    display: inline-block;
    width: 100vw;
    margin: 0 auto;
    text-align: center;
    font-size: 1.8rem;
    margin-top: 100px;
    vertical-align: middle;
}
.loader-wrapper span{
    margin-left: 1rem;
}

.loading-wrapper i, .loader-wrapper span {
    line-height: 1rem;
}