li{
    list-style: none
}
@font-face {
    font-family: ProximaNova;
    src: url('https://s3.amazonaws.com/100-cdn-sti/Janssen/assets/ProximaNova.otf');
}

html, body {
    position: relative;
    font-family: "ProximaNova", sans-serif;
    font-size: 10px;
    min-height: 100vh;
    margin: 0;
    margin-bottom: -60px;
    padding: 0;
}

#root {
    min-height: 100%;
}

hr {
    margin-top: 80px;
}

.welcome p {
    margin-bottom: 50px;
}
@media only screen and (max-width: 600px){
    h1 {
        font-size: 3.2rem !important;
    }
    h2 {
        font-size: 3.2rem !important;
    }
    h3 {
        font-size: 1.6rem !important;
    }
    h4 {
        font-size: 1.8rem !important;
    }
    p {
        font-size: 2.0rem !important;
    }
}


h1 {
    font-size: 4.8rem;
    color: #0E1E69;
    font-weight: 400;
    margin: 30px 0 0 0;
    letter-spacing: 0.5px;
}


h2 {
    color: #fff;
    font-size: 4.4rem;
    margin-bottom: 5px;
    font-weight: 400;
}


h3 {
    font-size: 2.0rem;
    color: #fff;
    font-weight: 600;
    margin: 0;
}


h4 {
    font-size: 2.4rem;
    color: #000;
    font-weight: 400;
    margin-bottom: 40px;
    margin-top: 24px;
}

p {
    font-size: 2.4rem;
    color: #373A40;
    font-weight: 400;
    margin: 20px 0 18px 0;
    line-height: 1.2
}

h1 span, h2 span, h3 span, h4 span {
    white-space: nowrap;
}

.row {
    margin: 0px !important;
}
.loader-wrapper {
    display: inline-block;
    width: 100vw;
    margin: 0 auto;
    text-align: center;
    font-size: 1.8rem;
    margin-top: 100px;
    vertical-align: middle;
}
.loader-wrapper span{
    margin-left: 1rem;
}

.loading-wrapper i, .loader-wrapper span {
    line-height: 1rem;
}
@media only screen and (max-width: 576px){
    .divider {
      display: block;
      height: 1px !important;
      width: 130px !important;
      border-width: 0 0 1px 0 !important;
    }
    .tagline-wrapper {
        display: block !important;
    }
}

.navbar-brand {
    height: auto !important;
    vertical-align: middle;
}

.navbar .logo {
   height: 60px;
}

.navbar .tagline {
    height: 40px;
 }

.navbar-brand .tagline-wrapper, .navbar-brand .logo{
    display: inline-block;
}


.menu {
    margin-right: 15px;
}

.divider {
    border-width: 0 1px 0 0;
    width: 1px;
    padding: 0px;
    margin: 0 10px 0 0;
    border-style: solid;
    border-color: #7d7d7d;   
}


.footer {
    position: absolute;
    width: 100%;
    height: auto;
    font-size: 1.4rem;
    background-color: #181818;
    color: #dadada;
    text-align: center;
    padding: 10px;
    
    bottom: 0;
}

.footer p {
    margin-bottom: 0px;
    margin-top: 0px;
    width: 70%;
    color: #dadada;
    font-size: 1.5rem;
    text-align: center;
}

.footer strong {
    white-space: nowrap;
}

.footer strong .anchor {
    color: #dadada;
}
@media only screen and (max-width: 1000px){
    .card.link {
        margin-bottom: 20px;
    }
}

    @media only screen and (max-width: 1200px){
        i {
            display: none !important;
        }
}

.box{
    background-color: red;
}

.card.link{
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    height: 300px;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.44);
}

.salutation {
    text-align: center;
}

.card.link:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    cursor: pointer;
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    box-shadow: 0px 4px 8px rgba(0,0,0,0.22);

}

.card.link .title {
    padding: 14px 24px;
    color: #FFFFFF;
    border-radius: 4px;
    border-width: 1px;
    border-color: #fff;
    border-style: solid;
    position: absolute;
    margin: 0 60px 52px 24px;
    bottom: 40px;
}

.card.link .card-footer {
    color: #fff;
    padding: 24px;
    min-height: 64px;
    margin: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    border: none;
}

.card-footer .row {
    display: block !important;

}

.card .material-icons {
    color: #fff;
    display: block;
}

.card-footer .arrow {
    text-align: right;
}

.card-footer h3 {
    display: inline-block;
    margin: 0;
}


