@media only screen and (max-width: 600px){
    h1 {
        font-size: 3.2rem !important;
    }
    h2 {
        font-size: 3.2rem !important;
    }
    h3 {
        font-size: 1.6rem !important;
    }
    h4 {
        font-size: 1.8rem !important;
    }
    p {
        font-size: 2.0rem !important;
    }
}


h1 {
    font-size: 4.8rem;
    color: #0E1E69;
    font-weight: 400;
    margin: 30px 0 0 0;
    letter-spacing: 0.5px;
}


h2 {
    color: #fff;
    font-size: 4.4rem;
    margin-bottom: 5px;
    font-weight: 400;
}


h3 {
    font-size: 2.0rem;
    color: #fff;
    font-weight: 600;
    margin: 0;
}


h4 {
    font-size: 2.4rem;
    color: #000;
    font-weight: 400;
    margin-bottom: 40px;
    margin-top: 24px;
}

p {
    font-size: 2.4rem;
    color: #373A40;
    font-weight: 400;
    margin: 20px 0 18px 0;
    line-height: 1.2
}

h1 span, h2 span, h3 span, h4 span {
    white-space: nowrap;
}
